<template>
  <div>
    <peeps-alert-animation ref="alert" :timeout="17000" v-bind="$attrs">
      <v-card outlined class="card-peeps-above peeps--text cursor-pointer">
        <v-container class="card-text">
          <p>
            You’ll next be entering your
            {{ parcelManaged == 'moreThanOne' ? 'first' : '' }} basic parcel
            information. If you don’t know some of the data, just skip them for
            now — you can always update them later. Since most of the
            information is publicly available, Peeps can help you pre-fill most
            of the data from these public sources. (Peeps is all about easy.)
          </p>
        </v-container>

        <div class="peeps-above-container">
          <div class="peeps-above">
            <v-img
              class="peeps-above-img"
              max-width="100"
              src="../../assets/img/PeepsAbove.svg"
            ></v-img>
          </div>
        </div>
        <div>
          <slot name="footer"></slot>
        </div>
      </v-card>
    </peeps-alert-animation>
  </div>
</template>
<script>
export default {
  props: {
    parcelManaged: {
      type: String
    }
  },
  mounted() {
    this.$refs.alert.animate()
  }
}
</script>

<style lang="sass" scoped>
@import "../../design/_colors.scss"
p
  line-height: 40px

.card-peeps-above
  .peeps-above-container
    padding-bottom: 100px
    position: absolute
    top: 0
    margin-top: -77px
    z-index: 2

.card-peeps-above
  margin-top: 5rem
  border-color: $color-peeps-green
</style>
